#inputID::placeholder {
  color: #343538;
  opacity: 1;
}
/* .click {
    display: flex;
    top: 10;
    position: relative;
    background-color: green;
    justify-content: center;
    width: 60px;
    left: 120;
    border-radius: 15;
    cursor: pointer;
  } */
.click:hover {
  width: 120px;
  background-color: black;
}
.outroClick {
  width: 50px;
  height: 50px;
  margin: auto;
}
.click {
  height: 50px;
  background-color: red;
  transition: all 0.5s;
}

.click:hover {
  margin-left: -100px;
}
/* main css for add button  */
#outer {
  width: 80px;
  height: 20px;
  margin: auto;
  position: relative;
  left: 40px;
  top: 10px;
}

#inner {
  height: 35px;
  background-color: rgb(69, 206, 69);
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

#inner:hover {
  margin-left: -50px;
}
.pagenum {
  display: none;
}
.pagination {
  display: flex;
}
.prepage {
  margin-right: 10px;
  background-color: #f6f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  width: 40px;
  height: 40px;
}
.nextpage {
  background-color: #f6f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  width: 40px;
  height: 40px;
}
.nextpage:hover {
  background-color: #499b0f;
}
.prepage:hover {
  background-color: #499b0f;
}
.form-group {
  margin-bottom: 5px;
  margin-left: 10px;
}
